import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { KOTI_API_ROOT } from '../Constants'
import Layout from '../Layouts/index'
import { Helmet } from 'react-helmet'
import { kotiTitleGenerator } from '../utils'

import './ihome.scss'

class IHomePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isSubmitting: false,
      modal: false,
      email: '',
      PDATE: this.getNextMonday()
        .hours(11)
        .minutes(0),
      FNAME: '',
      LNAME: '',
      PHONE: '',
      errorFields: [],
      message: '',
      endHour: 21,
    }
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      modal: true,
    })
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  handleDateChange = date => {
    const momentDate = moment(date)
    const isSaturday = momentDate.day() === 6
    const endHour = isSaturday ? 12 : 21

    if (isSaturday) {
      const format = 'hh:mm'
      const time = moment(momentDate.format(format), format)
      const beforeTime = moment('10:00', format)
      const afterTime = moment('12:00', format)

      if (!time.isBetween(beforeTime, afterTime, null, '[]')) {
        date = moment(date).hours(11).minutes(0)
      }
    }

    this.setState({
      ...this.state,
      PDATE: date,
      endHour,
    })
  }

  handleInputChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    })
  }

  resetFormErrors() {
    this.setState({
      ...this.state,
      errorFields: [],
    })
  }

  formSubmit = e => {
    e.preventDefault()
    this.resetFormErrors()
    const self = this
    console.log(this.state)
    fetch(`${KOTI_API_ROOT}/api/v1/subscribe`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...this.state,
        PDATE: this.state.PDATE.toISOString(true),
      }),
    })
      .then(res => {
        console.log(res)
        if (!res.ok) {
          res.json().then(function(data) {
            const errorFields = []
            for (const item of data) {
              errorFields.push(item.param)
            }
            self.setState({
              ...self.state,
              errorFields,
            })
          })
          return
        }

        // success
        res.json().then(function(data) {
          self.setState({
            ...self.state,
            message: data.message,
          })
        })
      })
      .catch(error => console.log(error))
  }

  isInvalid = name => {
    return this.state.errorFields.includes(name)
  }

  isNotSunday = date => {
    const day = moment(date).day()
    return day !== 0
  }

  getNextMonday = () => {
    const desiredWeekday = 1 // Monday
    const currentWeekday = moment().isoWeekday()
    const missingDays = (desiredWeekday - currentWeekday + 7) % 7
    const nextMonday = moment().add(missingDays, 'days')
    return nextMonday
  }

  render() {
    const modalBody = (
        <section className="page-ihome">
          <div className="section-left">
            <div className="img-wrapper">
              <img src={require('../assets/img/ihome-pack-large.png')} alt="alexa google" />
            </div>
          </div>
          <div className="section-right">
            
            <h1 className="header-h2">Delight Premium for iHome</h1>
            <p className="body-01-01">Let Delight brings you the smart home experience.</p>
            {this.state.message ? (
              <>
                <h3>{this.state.message}</h3>
                <Link
                  to="/"
                  className=""
                  style={{ color: 'white' }}
                >
                  Click here to learn more about delight
                </Link>
              </>
            ) : (
              <form className="" onSubmit={this.formSubmit}>
                <label htmlFor="FNAME">First Name</label>
                <input
                  type="text"
                  name="FNAME"
                  id="FNAME"
                  placeholder="Jon"
                  value={this.state.FNAME}
                  onChange={this.handleInputChange}
                  invalid={this.isInvalid('FNAME')}
                />
                <label htmlFor="LNAME">Last Name</label>
                <input
                  type="text"
                  name="LNAME"
                  id="LNAME"
                  placeholder="Snow"
                  value={this.state.LNAME}
                  onChange={this.handleInputChange}
                  invalid={this.isInvalid('LNAME')}
                />
                <label htmlFor="PHONE">Mobile Number</label>
                <input
                  type="tel"
                  name="PHONE"
                  id="PHONE"
                  placeholder="91234567"
                  value={this.state.PHONE}
                  onChange={this.handleInputChange}
                  invalid={this.isInvalid('PHONE')}
                />
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="jon@delight.ai"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  invalid={this.isInvalid('email')}
                />
                <label htmlFor="PDATE">
                  Click below to make an appointment for setup
                </label>
                <DatePicker
                  customInput={<ExampleCustomInput />}
                  withPortal
                  selected={this.state.PDATE}
                  onChange={this.handleDateChange}
                  popperPlacement="top-end"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '5px, 10px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                      boundariesElement: 'viewport',
                    },
                  }}
                  showTimeSelect
                  minDate={moment().add(5, 'days')}
                  minTime={moment()
                    .hours(11)
                    .minutes(0)}
                  maxTime={moment()
                    .hours(this.state.endHour)
                    .minutes(0)}
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="LLL"
                  timeCaption="time"
                  filterDate={this.isNotSunday}
                />
                <button
                  type="submit"
                  className="button submit-button"
                >
                    Submit
                  </button>
                <div className="">
                  <span className="body-01-01">
                    By submitting, you agree to the{' '}
                    <a href="/terms" target="_blank">
                      Terms of Service
                    </a>
                  </span>
                </div>
              </form>
            )}
          </div>
        </section>
    )

    // return (
    //   <>
    //     <Modal
    //       isOpen={this.state.modal}
    //       toggle={this.toggle}
    //       modalClassName="modal-fullscreen"
    //       className={`modal-container ${
    //         this.state.modal ? 'modal-active' : ''
    //       }`}
    //       backdrop="static"
    //     >
    //       {modalBody}
    //     </Modal>
    //     <LandingPage />
    //   </>
    // )
    return (
      <Layout location={this.props.location}>
        <Helmet title={kotiTitleGenerator('iHome')} />
        {modalBody}
      </Layout>
    )
  }
}

class ExampleCustomInput extends React.Component {
  render() {
    return (
      <button className="btn btn--lg" onClick={this.props.onClick}>
        {this.props.value}
      </button>
    )
  }
}
export default IHomePage
